import React, { useState, useEffect, useRef } from "react";
import Content from '../Content/Content';
// import {
//   validName,
//   validEmail,
//   validMessage,
//   validateName,
//   validateEmail,
//   validateMessage,
//   _handleNameChange,
//   _handleEmailChange,
//   nameInvalid,
//   emailInvalid
//  } from '../../helpers/formValidation';

const $ = require('jquery')
import swal from 'sweetalert';
import ReCAPTCHA from "react-google-recaptcha";


// function serialize(form){if(!form||form.nodeName!=="FORM"){return }var i,j,q=[];for(i=form.elements.length-1;i>=0;i=i-1){if(form.elements[i].name===""){continue}switch(form.elements[i].nodeName){case"INPUT":switch(form.elements[i].type){case"email":case"text":case"hidden":case"password":case"button":case"reset":case"submit":q.push(form.elements[i].name+"="+encodeURIComponent(form.elements[i].value));break;case"checkbox":case"radio":if(form.elements[i].checked){q.push(form.elements[i].name+"="+encodeURIComponent(form.elements[i].value))}break;case"file":break}break;case"TEXTAREA":q.push(form.elements[i].name+"="+encodeURIComponent(form.elements[i].value));break;case"SELECT":switch(form.elements[i].type){case"select-one":q.push(form.elements[i].name+"="+encodeURIComponent(form.elements[i].value));break;case"select-multiple":for(j=form.elements[i].options.length-1;j>=0;j=j-1){if(form.elements[i].options[j].selected){q.push(form.elements[i].name+"="+encodeURIComponent(form.elements[i].options[j].value))}}break}break;case"BUTTON":switch(form.elements[i].type){case"reset":case"submit":case"button":q.push(form.elements[i].name+"="+encodeURIComponent(form.elements[i].value));break}break}}return q.join("&")};

const ReservationForm = (props) => {
  // state = {
  //   email: '',
  //   name: '',
  //   message: '',
  //   // emailValid: true,
  //   // nameValid: true, // Name must not be blank when submit clicked
  //   // messageValid: true, // Message must not be blank when submit clicked
  //   emailClass: '',
  //   nameClass: '',
  //   subjectClass: '',
  //   messageClass: ''
  // }
  const [emailClass, setEmailClass] = useState('')
  const [nameClass, setNameClass] = useState('')
  const [subjectClass, setSubjectClass] = useState('')
  const [messageClass, setMessageClass] = useState('')

  const recaptchaRef = useRef();


  function messageInvalid() { // TODO return correct value
      return (
          <div>
          {/* <span className="icon is-small is-right">
                      <i className="fas fa-exclamation-triangle" style={{color: '#f9385f'}}></i>
                    </span> */}
                <p className="help is-danger">Please type a message</p>
                </div>
              )
  }
  // state = {
  //   selectedRoom: ''
  // }
  // componentWillMount() {
  //   this.setState({
  //     selectedRoom: this.props.room,
  //    })
  //   // this.props.parentThis.setState({ name: 'hello' })
  //   // TODO: Set the state here maybe so the white text in orange bg shows accurate settings/selections?

  // }

  // _handleMessageChange(e) {
  //   this.setState({ message: e.target.value })
  //   if (validMessage(e.target.value)) {
  //       this.setState({ messageValid: true, messageClass: '' })
  //   }
  // }
  // _handleSubmitClicked(e) {
  //   let nameIsValid = validName(this.state.name);
  //   let emailIsValid = validEmail(this.state.email);
  //   let messageIsValid = validMessage(this.state.message);
  //   if (nameIsValid && emailIsValid && messageIsValid) {
  //     // TODO: send msg
  //     console.log('submitted!')
  //   } else {
  //     this.setState(
  //       {
  //         nameValid: nameIsValid,
  //         emailValid: emailIsValid,
  //         messageValid: messageIsValid ,
  //         nameClass: nameIsValid ? '' : 'is-danger',
  //         emailClass: emailIsValid ? '' : 'is-danger',
  //         messageClass: messageIsValid ? '' : 'is-danger'
  //       })
  //   }
  // }

  // _handleFormSubmit(e) {
  //   // Problem with this is that zapier only receives the body of post if "Catch raw hook" is specified
  //   // and then it doesn't parse body
  //   e.preventDefault();
  //   // console.log(serialize(document.getElementById('contact-form')) );
  //   //console.log(new FormData(document.querySelector('#contact-form').entries()));
  //   const data = serialize(document.getElementById('contact-form'));
  //
  //   console.log(data);
  //
  //     (async () => {
  //   const rawResponse = await fetch('https://hooks.zapier.com/hooks/catch/3433564/qcpwo7/', {
  //     method: 'POST',
  //     // headers: {
  //     //   'Accept': 'application/json',
  //     //   // 'Content-Type': 'application/json'
  //     // },
  //     body: JSON.stringify(data)
  //   });
  //   const content = await rawResponse.json();
  //
  //   console.log(content);
  // })();
  // }
  // _handleRoomChange(e) {
  //   this.props.parentThis.setState({ room: e.target.value })
  //   this.setState({ selectedRoom: e.target.value })
  // }
  // _handlePeopleChange(e) {
  //   this.props.parentThis.setState({ people: e.target.value })
  // }

  // renderRooms() {
  //   return this.props.rooms.map((room) => {
  //     return <option value={room.name}>{room.name}</option>;
  //   })
  // }

  // renderPeople() {
  //   var index = this.props.rooms.map(function (room) { return room.name; }).indexOf(this.state.selectedRoom);
  //   // var numPeopleAry = [...Array(this.props.rooms[index].maxPeople - this.props.rooms[index].minPeople + 1).keys()]
  //   // console.log(numPeopleAry)
  //   var numAryItems = this.props.rooms[index].maxPeople - this.props.rooms[index].minPeople + 1;

  //   var numPeopleAry = Array.apply(null, Array(numAryItems)).map(function (_, i) { return i; });

  //   return numPeopleAry.map((number) => {
  //     return <option value={number + this.props.rooms[index].minPeople}>{number + this.props.rooms[index].minPeople}</option>;
  //   })
  // }

  function _handleNameChange(e) {
    if (e.target.value != "") {
      setNameClass('')
    }
  }

  function _handleEmailChange(e) {
    if (e.target.value != "") {
      setEmailClass('')
    }
  }

  function _handleSubjectChange(e) {
    if (e.target.value != "") {
      setSubjectClass('')
    }
  }

  function _handleMessageChange(e) {
    if (e.target.value != "") {
      setMessageClass('')
    }
  }


  function clearContactForm() {
    ['name', 'email', 'subject', 'message'].forEach((formElement) => {
      var element = document.getElementById('contact-' + formElement);
      element.value = '';
    });
  }

  function submitContactForm() {
    var invalid = false;
      ['name', 'email', 'subject', 'message'].forEach((formElement) => {
        var element = document.getElementById('contact-' + formElement);
        if (element.value == '') {
          invalid = true;
          switch (formElement) {
            case 'name':
              setNameClass("is-danger")
              break;
            case 'email':
              setEmailClass("is-danger")
              break;
            case 'subject':
              setSubjectClass("is-danger")
              break;
            case 'message':
              setMessageClass("is-danger")
              break;
          }
        } else {
          switch (formElement) {
            case 'name':
              setNameClass('')
              break;
            case 'email':
              setEmailClass('')
              break;
            case 'subject':
              setSubjectClass('')
              break;
            case 'message':
              setMessageClass('')
              break;
            }
        }
      });
      if (invalid) { return; }

      $.ajax({
          url:'https://hooks.zapier.com/hooks/catch/3433564/qcpwo7/',
          type:'post',
          data:$('#contact-form').serialize(),
          success:function(){
            // Redirect to another success page
            // window.location = "http://google.com";

            // window.location.href = "http://sverdrupservices.com";
            clearContactForm();
            swal('Thanks!', "We've received your message and will respond shortly.", 'success')
          }
      });
      // window.location.href = "http://sverdrupservices.com/about-us";
  }

  async function onSubmitWithReCAPTCHA (e) {
    e.preventDefault();
    const token = await recaptchaRef.current.executeAsync();

    // apply to form data
    submitContactForm();
  }

  useEffect(() => {
    $("#contact-form").submit(onSubmitWithReCAPTCHA);
  }, [])

  // render() {
       //   {/*<Content isSize='is-8' isOffset='is-offset-2'>*/}
    //  {/*<div>*/}

    return (
    <div style={{paddingTop: 15}}>
    <h2 className="is-size-2">{props.title}</h2>
    <p style={{marginBottom: 17}} className='is-size-6'>Fill out the contact form below and we'll get back to you promptly.</p>

    {/* <form className='ajaxForm' method="POST" action="https://formcarry.com/s/9xNJMw-ljMi" acceptCharset="UTF-8"> {/*TODO lambda function*/}
    {/* <form id='contact-form' onSubmit={this._handleFormSubmit}> */}
    <form id='contact-form'>
    <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey="6Lf8s-AZAAAAAMlZPcheslYI5PwfLOJ_vOLhk8jn"
      />

{/*Create columns so input is 1/2 width. set padding to 0 so theres no adding padding. Set minWidth 350 b/c overall wrapping happens quickly ??*/}
<div className="columns">
<div className="column" style={{paddingBottom: 0}}>
        <div className="field">
          <label for='contact-name' className="label">Name</label>
          <div className="control">
                {/* <input value={this.state.name} className={"input " + this.state.nameClass} type="text" placeholder="John Doe" onChange={_handleNameChange.bind(this)} onBlur={validateName.bind(this)} style={{minWidth:350 + 'px'}} /> */}
                {/* {!this.state.nameValid && nameInvalid()} */}
                <input id='contact-name' name='name' className={"input " + nameClass} type="text" placeholder="Your name"  data-lpignore="true" onChange={_handleNameChange}/>

          </div>
          </div>
</div>
<div className="column" style={{padding: 0}}></div>
</div>

<div className="columns">
<div className="column" style={{paddingBottom: 0}}>
            <div className="field">
                <label for='contact-email' className="label">Email</label>

               <div className="control">
                 {/* TODO: decide if we need this invalid class to highlight box in red border  */}
              {/* <input value={this.state.email} className={"input " + this.state.emailClass} type="email" placeholder="Email input" placeholder="name@example.com" onBlur={validateEmail.bind(this)} onChange={_handleEmailChange.bind(this)} style={{minWidth:350 + 'px'}} /> */}
<input id='contact-email' className={"input " + emailClass} type="email" name="email" placeholder="Work email" data-lpignore="true" onChange={_handleEmailChange}/>
               {/*    <span className="icon is-small is-left">
                    <i className="fas fa-envelope"></i>
                  </span> */}
                  {/* {!this.state.emailValid && emailInvalid()} */}
                   {/*<span className="icon is-small is-right">
                    <i className="fas fa-exclamation-triangle" style={{color: '#f9385f'}}></i>
                  </span>
               </div>
              <p className="help is-danger">This email is invalid</p>*/}
              </div>
          </div>

</div>
<div className="column" style={{padding: 0}}></div>
</div>




<div className="columns">
<div className="column" style={{paddingBottom: 0}}>
            <div className="field">
                <label for='contact-subject' className="label">Company Name</label>

               <div className="control">
                 {/* TODO: decide if we need this invalid class to highlight box in red border  */}
              {/* <input value={this.state.email} className={"input " + this.state.emailClass} type="email" placeholder="Email input" placeholder="name@example.com" onBlur={validateEmail.bind(this)} onChange={_handleEmailChange.bind(this)} style={{minWidth:350 + 'px'}} /> */}
<input id='contact-subject' className={"input " + subjectClass} type="subject" name="subject" placeholder="Company name" data-lpignore="true" onChange={_handleSubjectChange}/>
               {/*    <span className="icon is-small is-left">
                    <i className="fas fa-envelope"></i>
                  </span> */}
                  {/* {!this.state.emailValid && emailInvalid()} */}
                   {/*<span className="icon is-small is-right">
                    <i className="fas fa-exclamation-triangle" style={{color: '#f9385f'}}></i>
                  </span>
               </div>
              <p className="help is-danger">This email is invalid</p>*/}
              </div>
          </div>

</div>
<div className="column" style={{padding: 0}}></div>
</div>



<div className="field">
  {/*<div className="field-label is-normal">*/}
    <label className="label">Message</label>
  {/*</div>*/}
  <div className="field-body">
    <div className="field">
      <div className="control">
        {/* <textarea value={this.state.message} className={"textarea " + this.state.messageClass} placeholder="Explain how we can help you" rows="7" onChange={this._handleMessageChange.bind(this)} onBlur={validateMessage.bind(this)}></textarea> */}
<textarea id='contact-message' className={"textarea " + messageClass} name="message" placeholder="Your message" onChange={_handleMessageChange}></textarea>
        {/* {!this.state.messageValid && this.messageInvalid()} */}
      </div>
    </div>
  </div>
</div>


{/*<div class="field is-horizontal">*/}
  {/*<div class="field-label">*/}
    {/*<!-- Left empty for spacing -->*/}
  {/*</div>*/}
  <div className="field-body">
    <div className="field">
      <div className="control">
        {/* <a type='submit' className="button" onClick={this._handleSubmitClicked.bind(this)}>Send message</a> */}
        {/* <button type='submit' className="button">Send message</button> */}
        <input type='submit' value="Submit" className="button"/>


{/*        <button class="button is-primary" style={{ backgroundColor: '#118cff' }}>
          Send message
        </button>*/}
      </div>
    {/*</div>*/}
  </div>
</div>
</form>
        </div>
    )
  // };
}

export default ReservationForm;


{/*<div class="field">
  <label class="label">Email</label>
  <div class="control has-icons-left has-icons-right">
    <input class="input is-danger" type="email" placeholder="Email input" value="hello@">
    <span class="icon is-small is-left">
      <i class="fas fa-envelope"></i>
    </span>
    <span class="icon is-small is-right">
      <i class="fas fa-exclamation-triangle"></i>
    </span>
  </div>
  <p class="help is-danger">This email is invalid</p>
</div>*/}
