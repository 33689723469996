import React from "react";
import Helmet from "react-helmet";
// import SEO from "../components/SEO/SEO";
import config from "../../data/SiteConfig";

import Layout from "../components/layout";
import FullWidthImage from "../components/FullWidthImage/FullWidthImage";
import FullWidthImage2 from "../components/FullWidthImage2/FullWidthImage2";
import Content from "../components/Content/Content";
import ContactCard from "../components/ContactCard/ContactCard";
import Card from "../components/Card/Card";
import ContactForm from "../components/ContactForm/ContactForm";
import SocialProfiles from "../components/SocialProfiles/SocialProfiles";

// import img from "../imgs/karsten-wurth-inf1783-55271-unsplash-cropped.jpg";
// import img from "../imgs/american-public-power-association-419672-unsplash.jpg";
const img =
  "https://res.cloudinary.com/downtowndev/image/upload/q_auto,f_auto/american-public-power-association-419672-unsplash_c7cpaa.jpg";

class ContactUs extends React.Component {
  // https://www.google.com/maps/search/?api=1&query=centurylink+field
  // 12155 N Oracle Rd Oro Valley, AZ 85737

  render() {
    const emailInfo = (
      <p>
        <a href={`mailto:${config.email.contact}`}>{config.email.contact}</a>
      </p>
    );
    const phoneInfo = (
      <p>
        <a href={`tel:${config.userLinks[2].url}`}>{config.userLinks[2].url}</a>
      </p>
    );
    const addressInfo = (
      <p>
        <a
          href={`https://www.google.com/maps/search/?api=1&query=${config.address}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {config.address}
        </a>
      </p>
    );
    // Come visit us at
    return (
      <Layout location={this.props.location}>
        <div className="contact-container underNavbar" style={{ marginBottom: 20 }}>
          {" "}
          {/* TODO: This class doesn't exist */}
          <Helmet htmlAttributes={{ lang: "en" }} title={`Contact | ${config.siteTitle}`} />
          {/* <SEO postEdges={postEdges} /> */}
          {/* <FullWidthImage title={'Contact Us'} image={img} /> */}
          <FullWidthImage2 title="Contact Us" image={img} />
          <div className="container ">
            <div
              className="columns"
              style={{
                paddingBottom: 60,
                paddingTop: 30,
                justifyContent: "space-between"
              }}
            >
              <div className="column is-three-fifths">
                <ContactForm title="Get in touch!" />
              </div>
              <div className="column ">
                {/* <Content isSize='is-15' isOffset='is-offset-0' style={{paddingTop: 15}}> */}
                <div style={{ paddingTop: 50 }}>
                  {/* <ContactCard title="Address" info={addressInfo} /> */}
                  {/* <ContactCard title="Social" info={<SocialProfiles size={40} />}/> */}
                  <ContactCard title="Email" info={emailInfo} />
                  <ContactCard title="Phone" info={phoneInfo} />
                  <ContactCard title="Address" info={addressInfo} />
                  {/* <Card /> */}
                  {/* </Content> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default ContactUs;
