import React, { Component } from 'react';
import Link from 'gatsby-link';

class ContactCard extends Component {

  render() {
    return (
      <div className="card" style={{marginTop: 24}}>
        <div className="card-content" style={{paddingBottom: 0}}>
          <div className="media">
            <div className="media-content">
              <p className="title is-4" >{this.props.title}</p>
            </div>
          </div>

          <div className="content">
            {this.props.info}
            <br />
          </div>
        </div>
      </div>
    );
  };
};

export default ContactCard;
