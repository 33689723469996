import React, { Component } from 'react';
import Link from 'gatsby-link';
// import logo from '../../imgs/team/andrew-sverdrup.jpg';

class Card extends Component {

  render() {
    return (
      <div /*className="card"*/ style={styles.cardStyle}>
        <div className="card-image image is-square">
          <img src={require(`../../../assets/images/team/${this.props.member.image}`).default} alt="Placeholder image" />
        </div>
        <div /*className="card-content"*/>
          <div className="media">
            <div className="media-content">
              <p className="title is-4 has-text-centered has-text-weight-bold padding-top-10">{this.props.member.name}</p>
              <p className="subtitle is-5 has-text-centered">{this.props.member.role}</p>
            </div>
          </div>
          <div className="content" style={styles.description}>
            {this.props.member.description}
          </div>
        </div>
      </div>
    );
  };
};

const styles = {
  cardStyle: {
    margin: 10,
  },
  description: {
    paddingTop: 10,
    textAlign: 'justify',
  },
}

export default Card;
